import React, {useState, useEffect} from 'react';
import {AiFillEye, AiFillGithub} from 'react-icons/ai';
import {motion} from 'framer-motion';

import {AppWrap, MotionWrap} from '../../wrapper';
import {client, urlFor} from '../../client';
import './Work.scss';
import images from "../../constants/images";

type Works = {
    title: string;
    description: string;
    imgUrl: string;
    name: string;
    codeLink: string;
    projectLink: string;
    tags: string[];
}


const works = [
    {title: 'Hulu 2.0', description: 'A Reactive Hulu Clone', technologies: 'NextJS - TailwindCSS',
        imgUrl: images.hulu2, name: "blog", codeLink:'https://github.com/ldlearning/hulu-2', projectLink:'https://moviestreaming.codedthought.com/', tags:['Web App']},
    {title: 'Blog', description: 'A Reactive Blog', technologies: 'NextJS - TailwindCSS - GraphQL',
        imgUrl: images.blog, name: "blog", codeLink:'https://github.com/ldlearning/cms_blog', projectLink:'http://blog.danyleduc.com', tags:['Web App']},
    {title: 'AltSignal', description: 'A web based encrypted chat app using ReactJS', technologies: 'ReactJS',
        imgUrl: images.altSignal, name: "blog", codeLink:'https://github.com/ldlearning/alt-signal', projectLink:'http://altsignal.codedthought.com', tags:['Web App']},

]

const Work = () => {
    // const [works, setWorks] = useState([]);
    const [filterWork, setFilterWork] = useState(works);
    const [activeFilter, setActiveFilter] = useState('All');
    const [animateCard, setAnimateCard] = useState({y: 0, opacity: 1});

    // useEffect(() => {
    //     const query = '*[_type == "works"]';
    //
    //     client.fetch(query).then((data) => {
    //         setWorks(data);
    //         setFilterWork(data);
    //     });
    // }, []);


    const handleWorkFilter = (item) => {
        setActiveFilter(item);
        setAnimateCard([{y: 100, opacity: 0}]);

        setTimeout(() => {
            setAnimateCard([{y: 0, opacity: 1}]);

            if (item === 'All') {
                setFilterWork(works);
            } else {
                setFilterWork(works);
                setFilterWork(works.filter((work) => work.tags.includes(item)));
            }
        }, 250);
    };

    return (
        <>
            <h2 className="head-text">Some of my <span className={"gradient-text"}>Projects</span></h2>
            <div className="app__work-filter">
                {['Web App', 'Mobile App', 'Blockchain', 'IA', 'Other', 'All'].map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleWorkFilter(item)}
                        className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
                    >
                        {item}
                    </div>
                ))}
            </div>

            <motion.div
                animate={animateCard}
                transition={{duration: 0.5, delayChildren: 0.5}}
                className="app__work-portfolio"
            >
                {filterWork.map((work, index) => (
                    <div className="app__work-item app__flex" key={index}>
                        <div
                            className="app__work-img app__flex"
                        >
                            <img src={work.imgUrl} alt={work.name}/>
                            <motion.div
                                whileHover={{opacity: [0, 1]}}
                                transition={{duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5}}
                                className="app__work-hover app__flex"
                            >
                                <a href={work.projectLink} target="_blank" rel="noreferrer">
                                    <motion.div
                                        whileInView={{scale: [0, 1]}}
                                        whileHover={{scale: [1, 0.90]}}
                                        transition={{duration: 0.25}}
                                        className="app__flex"
                                    >
                                        <AiFillEye/>
                                    </motion.div>
                                </a>
                                <a href={work.codeLink} target="_blank" rel="noreferrer">
                                    <motion.div
                                        whileInView={{scale: [0, 1]}}
                                        whileHover={{scale: [1, 0.90]}}
                                        transition={{duration: 0.25}}
                                        className="app__flex"
                                    >
                                        <AiFillGithub/>
                                    </motion.div>
                                </a>
                            </motion.div>
                        </div>

                        <div className="app__work-content app__flex">
                            <div className="app__work-description app__flex">
                            <h4 className="p-title">{work.title}</h4>
                            <p className="p-description" style={{marginTop: 10}}>{work.description}</p>
                            <p className="p-technologies">{work.technologies}</p>
                            </div>
                            <div className="app__work-tag app__flex">
                                <p className="p-text">{work.tags[0]}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </motion.div>
        </>
    );
};

export default AppWrap(
    MotionWrap(Work, 'app__skills'),
    'work',
    'app__primarybg',
);