import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import api from '../assets/api.png';
import cicd from '../assets/cicd.png';

import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import docker from '../assets/docker.png';

import etherjs from '../assets/etherjs.png';

import figma from '../assets/figma.png';
import flutter from '../assets/flutter.png';
import git from '../assets/git.png';
import graphql from '../assets/graphql.png';
import html from '../assets/html.png';
import java from '../assets/java.png';
import javascript from '../assets/javascript.png';
import jenkins from '../assets/jenkins.png';
import keycloak from '../assets/keycloak.png';

import microservices from '../assets/microservices.png';

import mu5 from '../assets/mu5.png';
import nextjs from '../assets/nextjs.svg';
import node from '../assets/node.png';
import python from '../assets/python.png';
import rabbitmq from '../assets/rabbitmq.png';

import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';
import spring from '../assets/spring.png';

import sql from '../assets/sql.png';

import typescript from '../assets/typescript.png';
import vue from '../assets/vue.png';

import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';

import profile from '../assets/profile.png';
import profile01 from '../assets/profile01.svg';
import circle from '../assets/circle.svg';

import adidas from '../assets/adidas.png';
import amazon from '../assets/amazon.png';
import asus from '../assets/asus.png';
import bolt from '../assets/bolt.png';
import nb from '../assets/nb.png';
import skype from '../assets/skype.png';
import spotify from '../assets/spotify.png';

import solidity from '../assets/solidity.svg';

import artificialIntelligence from '../assets/Artificial Intelligence.svg'
import blockchain from '../assets/Blockchain.svg'

import altSignal from '../assets/altsignal.png'
import hulu2 from '../assets/hulu2.png'
import blog from '../assets/blog.png'

export default {
    email,
    mobile,
    api,
    cicd,
    cpp,
    css,
    docker,
    etherjs,
    figma,
    flutter,
    git,
    graphql,
    html,
    java,
    javascript,
    jenkins,
    keycloak,
    microservices,
    mu5,
    nextjs,
    node,
    python,
    rabbitmq,
    react,
    redux,
    sass,
    solidity,
    spring,
    sql,
    typescript,
    vue,


    about01,
    about02,
    about03,
    about04,
    profile,
    profile01,
    circle,
    adidas,
    amazon,
    asus,
    bolt,
    nb,
    skype,
    spotify,

    artificialIntelligence,
    blockchain,

    altSignal,
    hulu2,
    blog
};