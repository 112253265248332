import React, {useEffect, useState} from 'react';
import SocialMedia from "../components/SocialMedia";
import NavigationDots from "../components/NavigationDots";
import {BsLinkedin} from "react-icons/bs";


//Higher Order Component
const AppWrap = (Component, idName, classNames?, includeCopyright?) => function HOC() {

    return (
        <div id={idName} className={`app__container ${classNames}`}>
            <SocialMedia/>
            <div className={"app__wrapper app__flex"}>
                <Component/>

                {includeCopyright &&
                <div className={"app__footer-container"} >
                    <a className={"app__footer-social"} href="https://www.linkedin.com/in/dany-leduc-175a9386/"><BsLinkedin/></a>

                    <div className={"copyright"}>
                    <p className={"p-text"}>@2023 Dany Leduc</p>
                    <p className={"p-text"}>All rights reserved</p>
                </div>
                </div>
                }


            </div>
            <NavigationDots active={idName}/>
        </div>
    )
}

export default AppWrap
