import React from 'react';
import {motion} from 'framer-motion';

import './Header.scss';
import images from "../../constants/images";
import {AppWrap, MotionWrap} from "../../wrapper";

const scaleVariants = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 1,
            ease: 'easeInOut',
        },
    },
};

const Header = () => {
    return (
        <div id={"header"} className="app__header app__flex">
            <motion.div
                whileInView={{x: [-100, 0], opacity: [0, 1]}}
                transition={{duration: 0.5}}
                className="app__header-info"
            >
                <div className="app__header-badge">
                    <div className="badge-cmp app__flex">
                        <span>👋</span>
                        <div style={{marginLeft: 20}}>
                            <h1 className="head-text">Hello !</h1>
                            <p className="p-text"> Welcome to my personal website</p>


                        </div>
                    </div>
                    <div className="tag-cmp app__flex">
                        <p className="p-text">Full-Stack Developer</p>
                        <p className="p-text">Blockchain Developer</p>
                        <p className="p-text">Artificial Intelligence</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                whileInView={{opacity: [0, 1]}}
                transition={{duration: 0.5, delayChildren: 0.5}}
                className="app__header-img"
            >
                <img src={images.profile01} alt="profile_bg" width={"500px"} height={"500px"}/>
                <motion.img
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    src={images.circle}
                    alt="profile_circle"
                    className="overlay_circle"
                />
            </motion.div>

            <motion.div
                variants={scaleVariants}
                whileInView={scaleVariants.whileInView}
                className="app__header-circles"
            >
                {[images.react, images.graphql, images.solidity].map((circle, index) => (
                    <div className="circle-cmp app__flex" key={`circle-${index}`}>
                        <img src={circle} alt="profile_bg"/>
                    </div>
                ))}
            </motion.div>
        </div>
    )
}
// export default AppWrap(Header, 'home');
export default AppWrap(
    MotionWrap(Header, 'app__header'),
    'home',
    'app__primarybg',
);