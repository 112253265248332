import React from 'react';
import {BsLinkedin, BsTwitter} from "react-icons/bs";

const SocialMedia = () => {

    return (
        <div className={"app__social"}>
            <div>
                <a href="https://www.linkedin.com/in/dany-leduc-175a9386/"><BsLinkedin/></a>
            </div>
        </div>
    )
}

export default SocialMedia
