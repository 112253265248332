import React from 'react';
import logo from './logo.svg';
import './App.scss';
import NavBar from "./components/NavBar/NavBar";
import {About, Footer, Header, Skills, Testimonials, Work} from "./container";


function App() {
    return (
        <div className={"app"}>
            <NavBar/>
            <Header/>
            <About/>
            <Work/>
            {/*<Skills/>*/}
            {/*<Testimonials/>*/}
            <Footer/>
        </div>
    );
}

export default App;
