import React, {useState, useEffect} from 'react';
import {motion} from 'framer-motion';
import './About.scss';
import images from "../../constants/images";
import {client, urlFor} from "../../client";
import {AppWrap, MotionWrap} from "../../wrapper";
// import { urlFor, client } from '../../client';

type About = {
    title: string;
    description: string;
    imgUrl: string;
}

type Skill = {
    title: string;
    description: string;
    imgUrl: string;
}

const abouts = [
    {title: 'Full-Stack Develoment', description: 'I have 10+ years of experience developing financial application for the banking and insurance sector.', imgUrl: images.about04, imgBkg: ""},
    {title: 'Blockchain Develoment', description: 'I have 5+ years of experience developing smart contracts and web3 apps (Tokens, NFTs, Exchanges, ...)', imgUrl: images.blockchain, imgBkg: "#FEEBC8"},
    {title: 'Artificial Intelligence', description: "I have a good knowledge of OpenAI API. I'm currently learning ML models too.", imgUrl: images.artificialIntelligence, imgBkg: "#e7e7e7"},
    {title: 'Architecture', description: 'I have a good experience designing fast, reliable, and scalable enterprise level applications (docker, microservices, CI/CD, ...)', imgUrl: images.about01, imgBkg: ""}
]

const skills = [


    {name: 'Javascript', icon: images.javascript},
    {name: 'TypeScript', icon: images.typescript},

    {name: 'React', icon: images.react},
    {name: 'NextJS', icon: images.nextjs},
    {name: 'Redux', icon: images.redux},

    {name: 'Java', icon: images.java},
    {name: 'Spring', icon: images.spring},

    {name: 'Solidity', icon: images.solidity},
    {name: 'EtherJS', icon: images.etherjs},

    {name: 'Swift', icon: images.mobile},

    {name: 'GraphQL', icon: images.graphql},

    {name: 'SQL', icon: images.sql},

    {name: 'HTML / CSS', icon: images.html},

    {name: 'Docker', icon: images.docker},

    {name: 'RabbitMQ', icon: images.rabbitmq},

    {name: 'Microservices', icon: images.microservices},

    {name: 'Jenkins', icon: images.jenkins},
    {name: 'Keycloak', icon: images.keycloak},
    {name: 'CI/CD', icon: images.cicd},
    {name: 'Git', icon: images.git},

    {name: 'And much more..', icon: ""},


]

const About = () => {
    // const [abouts, setAbouts] = useState<About[]>([]);
    // const [skills, setSkills] = useState([]);

    // useEffect(() => {
    //     const query = '*[_type == "abouts"]';
    //     const skillsQuery = '*[_type == "skills"]';
    //
    //     client.fetch(query).then((data) => {
    //         setAbouts(data);
    //     });
    //     client.fetch(skillsQuery).then((data) => {
    //         setSkills(data);
    //     });
    // }, []);

    return (
        <>
            <h2 className="head-text">You have the idea? <br/>We have <span
                className={"gradient-text"}>the code !</span>
            </h2>


            <p className="profile-text ">Being a new technologies lover and having 10+ years of experience in development,
                in finance in particular, I aquired a certain knowledge in various fields.
            </p>

            <div className="app__profiles">

                {abouts.map((about, index) => (
                    <motion.div
                        whileInView={{opacity: 1}}
                        whileHover={{scale: 1.1}}
                        transition={{duration: 0.2, type: 'tween'}}
                        className="app__profile-item"
                        key={about.title + index}
                    >
                        {about.imgBkg !== "" &&
                        <img src={about.imgUrl!} alt={about.title} style={{backgroundColor: about.imgBkg}}/>
                        }
                        {about.imgBkg == "" &&
                        <img src={about.imgUrl!} alt={about.title}/>
                        }

                        <h2 className="bold-text" style={{marginTop: 20}}>{about.title}</h2>
                        <p className="p-text" style={{marginTop: 10}}>{about.description}</p>
                    </motion.div>
                ))}
            </div>
            <br/>


            <div className="app__skills-container">
                <h2 className="head-text">Languages & <span className={"gradient-text"}>frameworks</span></h2>
                <motion.div className="app__skills-list">
                    {skills.map((skill, index) => (
                        <motion.div
                            whileInView={{opacity: [0, 1]}}
                            transition={{duration: 0.5}}
                            className="app__skills-item app__flex"
                            key={skill.name + index}
                        >
                            <div
                                className="app__flex"
                                style={{backgroundColor: skill.bgColor}}
                            >

                                {skill.icon !== "" &&
                                    <img src={skill.icon} alt={skill.name}/>
                                }
                                <p className="p-text">{skill.name}</p>
                            </div>

                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </>
    )
}

export default AppWrap(
    MotionWrap(About, 'app__about'),
    'about',
    'app__whitebg',
);