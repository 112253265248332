import React, {useState} from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
import './NavBar.scss'

const NavBar = () => {
    const [toggle, setToggle] = useState(false);

    return (
        <nav className="app__navbar">
            <div className="app__navbar-title">
               Dany Leduc
            </div>
            <ul className="app__navbar-links">
                {['home', 'about', 'work', 'contact'].map((item) => (
                    <li className="app__flex p-text" key={`link-${item}`}>
                        <div />
                        <a href={`#${item}`}>{item}</a>
                    </li>
                ))}
                <li className="app__flex p-text" key={`link-blog`}>
                    <div />
                    <a href={`http://blog.danyleduc.com`}>blog</a>
                </li>
            </ul>

            <div className="app__navbar-menu">
                <HiMenuAlt4 onClick={() => setToggle(true)} />

                {toggle && (
                    <motion.div
                        whileInView={{ x: [300, 0] }}
                        transition={{ duration: 0.85, ease: 'easeOut' }}
                    >
                        <HiX onClick={() => setToggle(false)} />
                        <ul>
                            {['home', 'about', 'work', 'contact'].map((item) => (
                                <li key={item}>
                                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                                        {item}
                                    </a>
                                </li>
                            ))}
                            <li key='blog'>
                                <a href={`http://blog.danyleduc.com`} onClick={() => setToggle(false)}>
                                    blog
                                </a>
                            </li>
                        </ul>
                    </motion.div>
                )}
            </div>
        </nav>
    );
}

export default NavBar